import { toast } from "@/shared_components/toast";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import https from "https";
import { produce, setAutoFreeze } from "immer";
import ndjson from "ndjson";
import { getValidIdToken } from "next-firebase-auth-edge/lib/next/client";
import { StreamError } from "../errors/StreamError";

interface ErrorResponse {
  message?: string;
  detail?: string;
}

class ApiManager {
  private axiosInstance: AxiosInstance;
  private requestRegistry: Map<string, number>;
  private debounceInterval: number = 4000;
  private retryCount: number = 1;
  private retryDelay: number = 1000; // 1 second

  /**
   *  The IdToken used to authenticate requests.
   *  This token is initialized in the Root layout server component
   *  via the AuthContext (https://next-firebase-auth-edge-docs.vercel.app/docs/getting-started/layout#example-rootlayout)
   */
  public serverIdToken: string | null = null;

  constructor(baseURL: string) {
    const httpsAgent = new https.Agent({ keepAlive: true });
    this.axiosInstance = axios.create({
      baseURL,
      httpsAgent,
    });
    this.requestRegistry = new Map();
  }

  /**
   *  Ensure outgoing requests have latest valid idToken before execution.
   *  If the token has expired, this function will refresh the token by calling
   *  the refresh endpoint auto-configured by the next-firebase-auth-edge middleware.
   *  This function is fast and safe for repeated calls.
   */
  public async refreshIdToken() {
    if (!this.serverIdToken) {
      console.warn("Initial IdToken is not yet set. Skipping refresh.");
    } else {
      this.serverIdToken = await getValidIdToken({
        serverIdToken: this.serverIdToken,
        refreshTokenUrl: "/api/refresh-token",
      });
    }
  }

  private generateRequestKey(url: string, data?: any): string {
    const dataString = JSON.stringify(data);
    return `${url}:${dataString}`;
  }

  private shouldProceedWithRequest(url: string, data?: any): boolean {
    const key = this.generateRequestKey(url, data);
    const now = Date.now();
    const lastRequestTime = this.requestRegistry.get(url);

    if (lastRequestTime && now - lastRequestTime < this.debounceInterval) {
      return false;
    }

    this.requestRegistry.set(key, now);
    return true;
  }

  private selectBaseUrl(roleType: string): string {
    switch (roleType) {
      case "Admin":
        return this.axiosInstance.defaults.baseURL + "v1/" || "";
      case "User":
        return this.axiosInstance.defaults.baseURL + "v1/" || "";
      default:
        return this.axiosInstance.defaults.baseURL + "v1/" || "";
    }
  }

  private async request<T>(
    method: "get" | "post" | "delete",
    url: string,
    roleType: string,
    data?: any,
    config?: AxiosRequestConfig,
    showToastMessage: boolean = true,
    skipTokenRefresh: boolean = false,
  ): Promise<T> {
    if (!skipTokenRefresh) {
      await this.refreshIdToken();
    }

    if (!this.shouldProceedWithRequest(url, data)) {
      throw new Error("Request blocked due to rapid repeat attempts");
    }
    try {
      const baseURL = this.selectBaseUrl(roleType);
      const fullUrl = `${baseURL}${url}`;
      const headers = this.serverIdToken ? { Authorization: `Bearer ${this.serverIdToken}` } : {};
      const finalConfig = {
        ...config,
        headers: { ...config?.headers, ...headers },
      };

      let response: AxiosResponse<T>;

      switch (method) {
        case "get":
          response = await this.axiosInstance.get<T>(fullUrl, finalConfig);
          break;
        case "post":
          response = await this.axiosInstance.post<T>(fullUrl, data, finalConfig);
          break;
        case "delete":
          response = await this.axiosInstance.delete<T>(fullUrl, finalConfig);
          break;
        default:
          throw new Error(`Request method ${method} not supported`);
      }

      return this.handleResponse(response);
    } catch (error) {
      this.handleError(error, showToastMessage);
    }
  }

  private handleResponse<T>(response: AxiosResponse<T>): T {
    const processedData = this.processResponseData(response.data);
    return { ...processedData, response };
  }

  private processResponseData(data: any): any {
    // Process the data as needed
    // For instance, if you need to transform the data, do it here
    // This is just a placeholder function. Implement the logic based on your requirements.
    return data;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleError(error: any, showToastMessage: boolean): never {
    let message = "An unexpected error occurred";
    let statusCode = 0; // Default status code

    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ErrorResponse>;
      statusCode = axiosError.response?.status || 0;
      if (axiosError.response) {
        // Extract message from the server response
        const serverMessage = axiosError.response.data.message || axiosError.response.data.detail;
        message = serverMessage || message;
      } else if (axiosError.request) {
        // Request was made but no response was received
        message = "No response was received. Please check your network connection.";
      } else {
        // Something happened in setting up the request
        message = `Error in request setup: ${axiosError.message}`;
      }
    } else if (error instanceof Error) {
      message = `Unexpected error: ${error.message}`;
    } else {
      message = "An unknown error occurred.";
    }

    console.error("Network error details:", {
      message,
      statusCode,
      originalError: error,
    });

    if (showToastMessage) {
      toast({ title: message, type: "error" });
    }

    throw Object.assign(new Error(message), {
      statusCode,
      originalError: error,
    });
  }

  public async get<T>(
    url: string,
    roleType: string,
    config?: AxiosRequestConfig,
    showToastMessage: boolean = true,
    skipTokenRefresh: boolean = false,
  ): Promise<T> {
    return this.request("get", url, roleType, null, config, showToastMessage, skipTokenRefresh);
  }

  public async post<T>(
    url: string,
    roleType: string,
    data?: any,
    config?: AxiosRequestConfig,
    showToastMessage: boolean = true,
  ): Promise<T> {
    return this.request("post", url, roleType, data, config, showToastMessage);
  }

  public async delete<T>(
    url: string,
    roleType: string,
    config?: AxiosRequestConfig,
    showToastMessage: boolean = true,
  ): Promise<T> {
    return this.request("delete", url, roleType, null, config, showToastMessage);
  }

  private async _fetchWithRetries(url: string, config: RequestInit, retries: number): Promise<Response> {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const response = await fetch(url, config);

        // Retry on 5xx server errors and 401/403 authorization errors
        if (!response.ok) {
          if (response.status >= 500 || response.status === 401 || response.status === 403) {
            console.warn(`Server error: ${response.status}. Retrying...`);
            throw new Error(`Server error: ${response.status}`);
          }

          // For other client errors (4xx), do not retry
          if (response.status >= 400 && response.status < 500) {
            console.error(`Client error: ${response.status}. Not retrying.`);
            throw new Error(`Client error: ${response.status}`);
          }
        }

        // If the response is OK, return it
        return response;
      } catch (error) {
        // Narrowing the error type
        if (error instanceof Error) {
          if (error.name === "AbortError") {
            console.error("Fetch was aborted. Not retrying.");
            throw error;
          }

          // Log the error message for debugging
          console.warn(`Attempt ${attempt} failed with error:`, error.message, error.stack, error.name);

          // Determine if this is a network error
          if (error.message.includes("NetworkError") || error.message.includes("Failed to fetch")) {
            console.error("Network error detected. Not retrying.");
            throw error; // Fail immediately on network errors
          }
        }

        if (attempt === retries) {
          console.error("Maximum retry attempts reached. Giving up.");
          throw error; // Re-throw the original error if retries are exhausted
        }

        // Exponential backoff before retrying
        const delay = this.retryDelay * Math.pow(2, attempt - 1);
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }

    throw new Error("Exceeded maximum retries");
  }

  public async stream(
    url: string,
    roleType: string,
    data: any,
    onEvent: (event: any) => void,
    onEnd: () => void,
    customConfig?: RequestInit,
    enableDebugLogging: boolean = false,
  ): Promise<Response> {
    const baseURL = this.selectBaseUrl(roleType);
    const fullUrl = `${baseURL}${url}`;
    const streamStartTime = Date.now();

    await this.refreshIdToken();

    if (enableDebugLogging) {
      console.group(`HTTP Stream initiated @ ${new Date().toLocaleString(undefined, { timeZoneName: "short" })}`);
    }

    let defaultConfig: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/x-ndjson",
        Authorization: `Bearer ${this.serverIdToken}`,
      },
    };
    setAutoFreeze(false);
    let config = produce(defaultConfig, (draft) => {
      if (customConfig) {
        // Merge root level properties of customConfig
        Object.assign(draft, customConfig);

        // If headers exist in customConfig, merge them with defaultConfig.headers
        if (customConfig?.headers) {
          draft.headers = { ...defaultConfig.headers, ...customConfig.headers };
        }
      }
      draft.body = data;
    });

    if (customConfig?.signal) {
      config.signal = customConfig.signal;
    }
    setAutoFreeze(true);

    const response = await fetch(fullUrl, config);
    const status = response.status;
    const body = response.body;

    if (!body) {
      throw new Error("No body available in the response");
    }

    // Check for request errors
    if (!response.ok) {
      let responseJSON;
      try {
        responseJSON = await response.json();
      } catch (error) {
        throw new StreamError((error as Error).message, status, "OTHER", error);
      }

      if (status === 400 && responseJSON?.detail === "User message is empty") {
        throw new StreamError(responseJSON.detail, status, "USER_MESSAGE_EMPTY", responseJSON);
      } else {
        throw new StreamError(responseJSON?.detail, status, "OTHER", responseJSON);
      }
    } else {
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      // Create a stream that pipes the response through the ndjson parser
      const readStream = async () => {
        const parser = ndjson.parse();
        // Handle each parsed JSON object (NDJSON event)
        parser.on("data", (data) => {
          if (enableDebugLogging) {
            console.log(
              `${data.type} event received (${((Date.now() - streamStartTime) / 1000).toFixed(2)}s elapsed)`,
              data,
            );
          }
          onEvent(data);
        });
        // Error handling for parser
        parser.on("error", (err: any) => {
          this.handleError(err, false);
        });

        let isDone = false;
        try {
          while (!isDone) {
            const { done, value } = await reader.read();
            isDone = done;

            if (value) {
              const chunk = decoder.decode(value, { stream: true });
              parser.write(chunk);
            }
          }
        } catch (err: any) {
          if (enableDebugLogging) {
            console.groupEnd();
          }
          if (err?.name === "AbortError") {
            console.warn("Stream request aborted");
          } else {
            this.handleError(err, false);
          }
        }
        if (enableDebugLogging) {
          console.groupEnd();
        }
        parser.end();
        onEnd();
      };
      // Start reading the stream
      await readStream();
      return response;
    }
  }
}

export default ApiManager;
