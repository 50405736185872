import { getFirstPartOfSubdomain } from "@repo/utils";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getOrgIdFromUrl(url: string) {
  if (url === "amigo.local.ai:3000" || url.includes("preview.amigo.ai")) {
    return "dogfood";
  }

  return url.split(".")[0];
}

export function decodeToken(token: string) {
  return JSON.parse(atob(token));
}

export function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  // Remove the link after downloading
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function getBrowser() {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Edg/")) {
    return "Edge";
  } else if (userAgent.includes("OPR/") || userAgent.includes("Opera")) {
    return "Opera";
  } else if (userAgent.includes("Mobile") && userAgent.includes("CriOS")) {
    return "Chrome Mobile";
  } else if (userAgent.includes("Chrome/") && !userAgent.includes("Edg/")) {
    return "Chrome";
  } else {
    return "Other";
  }
}

export function getIsVoiceControlCompatibleWithCurrentBrowserEnvironment() {
  const browser = getBrowser();
  if (browser === "Chrome" || browser === "Edge" || browser === "Opera") {
    return true;
  } else {
    return false;
  }
}

export function getIsVoiceCallModeCompatibleWithCurrentBrowserEnvironment() {
  const browser = getBrowser();
  if (browser === "Chrome" || browser === "Edge" || browser === "Opera") {
    return true;
  } else {
    return false;
  }
}

export function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
}

export function getOrgIdFromHost(host: string) {
  let orgId = getFirstPartOfSubdomain(host);
  if (orgId === "coachbot") {
    orgId = "newlevel";
  }

  if (orgId === "preview") {
    orgId = "dogfood";
  }

  orgId = host === "amigo.local.ai:3000" ? "dogfood" : orgId;

  return orgId;
}

export function pluralize(word: string, count: number) {
  return count === 1 ? word : `${word}s`;
}

export function ensureUnreachable(value: never, message?: string): never {
  throw new Error(message || `Unreachable code hit with value ${String(value)}`);
}

export function getRedirectLink(hostName: string, orgId: string, isDevelopment: boolean, encodedEmail: string) {
  if (hostName.includes("preview.amigo.ai")) {
    return `https://${hostName}/login?email=${encodedEmail}`;
  }
  if (isDevelopment) {
    return `https://amigo.local.ai:3000/login?email=${encodedEmail}`;
  } else {
    return `https://${orgId}.amigo.ai/login?email=${encodedEmail}`;
  }
}
