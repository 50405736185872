import { Typography } from "./typography";

interface CommonButtonProps {
  size?: "none" | "small" | "medium" | "large";
  weight?: "400" | "500" | "700";
  className?: string;
  variant?: "primary" | "secondary" | "outline";
  text: string;
  disabled?: boolean;
  id?: string;
  rightIcon?: React.ReactNode;
}

interface SubmitButtonProps extends CommonButtonProps {
  type: "submit";
}

interface ButtonWithHandlerProps extends CommonButtonProps {
  type: "button";
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export type ButtonProps = SubmitButtonProps | ButtonWithHandlerProps;

export function Button(props: ButtonProps) {
  const { className, text = "Button", variant = "primary", size, type, disabled, rightIcon, weight, id } = props;

  const variantClass = {
    primary: disabled ? "bg-surface-disabled" : "bg-surface-primary text-white",
    secondary: disabled ? "text-content-primary" : "bg-white text-content-primary",
    outline: disabled ? "text-content-support" : "bg-white border border-border-primary text-content-support",
  }[variant];

  const sizeClass = {
    none: "",
    small: "px-4 py-2 rounded-xl",
    medium: "px-8 py-3 rounded-2xl",
    large: "px-12 py-4 rounded-[20px]",
  }[size || "medium"];

  const textClass = disabled ? "text-content-support" : "cursor-pointer";
  const hasOnClick = props.type !== "submit";

  return (
    <button
      id={id}
      className={`shadow-button group relative flex items-center justify-center gap-2 transition duration-300 ease-in-out ${variantClass} ${className} ${sizeClass}`}
      disabled={disabled}
      type={type}
      onClick={hasOnClick ? props.onClick : undefined}
    >
      <Typography className={`${textClass}`} variant="label" weight={weight ?? "400"}>
        {text}
      </Typography>
      {rightIcon}
      <div className="bg-state-hover absolute inset-0 opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100"></div>
    </button>
  );
}
