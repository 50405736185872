import { BaseGetParams, GetUserResponse, GetUsersResponse } from "@interfaces/";
import { AxiosResponse } from "axios";
import apiClient from "./ApiClient";

export const conversationsByUser = async (orgId: string, userId: string, role: string) => {
  return await apiClient.get(`${orgId}/conversation/?user_id=${userId}`, role, undefined, true);
};

export const getUsersStats = async (orgId: string, role: string, payload: any) => {
  return await apiClient.post(`user/${orgId}/user_stat/`, role, payload, undefined, true);
};

export const inviteUser = async (orgId: string, role: string, payload: any) => {
  return await apiClient.post(`${orgId}/user/invite`, role, payload, undefined, true);
};

export const getUsers = async (orgId: string, role: string): Promise<GetUsersResponse> => {
  return await apiClient.get(`${orgId}/user/`, role, undefined, true);
};

export const resendInviteLink = async (orgId: string, userId: string, role: string, payload: any) => {
  return await apiClient.post(`${orgId}/user/${userId}/resend_invite_link`, role, payload, undefined, false);
};

export const getUserRoles = async (orgId: string, role: string) => {
  return await apiClient.get(`${orgId}/role/`, role, undefined, true);
};

interface getUserParams extends BaseGetParams {
  userId: string;
}

export const getUser = async ({ orgId, role, userId, showToast = true }: getUserParams): Promise<GetUserResponse> => {
  return await apiClient.get(`${orgId}/user/${userId}`, role, undefined, showToast);
};

export const getMagicLink = async (orgId: string, role: string, payload: any) => {
  return await apiClient.post(`${orgId}/user/signin`, role, payload, undefined, true);
};

export const verifyUser = async (params: {
  orgId: string;
  userId: string;
  role: string;
  firstName?: string;
  lastName?: string;
}) => {
  const { orgId, userId, role, firstName, lastName } = params;
  return await apiClient.post<{ response: AxiosResponse<string> }>(
    `${orgId}/user/${userId}/verify`,
    role,
    { first_name: firstName, last_name: lastName },
    undefined,
    true,
  );
};

export const directSignup = async (params: { orgId: string; email: string; firstName: string; lastName: string }) => {
  const { orgId, firstName, lastName, email } = params;
  return await apiClient.post(
    `${orgId}/user/self-serve`,
    "Admin",
    { first_name: firstName, last_name: lastName, email },
    undefined,
    true,
  );
};

interface UpdateUserPayload {
  enable_response_recommendation?: boolean;
  first_name?: string;
  last_name?: string;
}

export async function updateUser(params: { orgId: string; userId: string; payload: UpdateUserPayload }) {
  const { orgId, userId, payload } = params;
  return await apiClient.post(`${orgId}/user/${userId}/user`, "Admin", payload, undefined, true);
}
